import axios from "axios";
import { agent } from "../utils/agent";
const API_URL = process.env.REACT_APP_API_URL;

const register = (username, email, password) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return axios.post(
    API_URL + "signup",
    {
      username,
      email,
      password,
    },
    config
  );
};

const login = (email, password, rememberMe) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
      rememberMe,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem(
          "userPremimium",
          JSON.stringify(response.data.userPremimium)
        );
        localStorage.setItem("token", response.data.token);
        localStorage.setItem(
          "club_details",
          JSON.stringify(response.data.club_details)
        );
      }

      return response.data;
    });
};

const adminLogin = (data) => {
  return axios.post(API_URL + "AdminLogin", data).then((response) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);
      localStorage.setItem(
        "club_details",
        JSON.stringify(response.data.club_details)
      );
    }

    return response.data;
  });
};

const checkLoginUser = async () => {
  const response = await agent.get(API_URL + "checkLogin");
  return response.data;
};

const gameSignup = async (formValue) => {
  const response = await agent.get(API_URL + "game-signup", { formValue });
  return response.data;
};

const userSignup = async (formValue) => {
  const response = await axios.post(API_URL + "user-signup", formValue);
  return response.data;
};
const accountVerification = async (verificationLink) => {
  const response = await axios.get(
    API_URL + "accountVerification/" + verificationLink
  );
  return response.data;
};
const resetPassword = async (verificationLink) => {
  const response = await axios.get(
    API_URL + "resetPassword/" + verificationLink
  );
  return response.data;
};
const saveResetPassword = async (formValue) => {
  const response = await axios.post(API_URL + "saveResetPassword", formValue);
  return response.data;
};
const forgotPassword = async (formValue) => {
  const response = await axios.post(API_URL + "forgotPassword", formValue);
  return response.data;
};

const logout = () => {
  const storedEmail = localStorage.getItem("storedEmail") ?? "";
  const storedPassword = localStorage.getItem("storedPassword") ?? "";
  localStorage.clear();
  localStorage.setItem("storedEmail", storedEmail);
  localStorage.setItem("storedPassword", storedPassword);
};

const authService = {
  register,
  login,
  adminLogin,
  logout,
  gameSignup,
  userSignup,
  checkLoginUser,
  accountVerification,
  forgotPassword,
  resetPassword,
  saveResetPassword,
};

export default authService;
