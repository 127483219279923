import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import CommonService from "../../services/common.service";
const BrandingDetailModal = (props) => {
  var brandingDetails = JSON.parse(localStorage.getItem("branding"));
  if (!brandingDetails) {
    brandingDetails = props.brandingData?.branding_data;
  }
  var clubDetails = JSON.parse(localStorage.getItem("club_details"));
  if (!clubDetails) {
    clubDetails = props.brandingData;
  }
  const saveUserClick = (websiteLink, type, id) => {
    const data = {};
    data.type = type;
    data.type_id = id;
    CommonService.saveUserClick(data).then((res) => {
      //window.location.href = websiteLink;
      window.open(websiteLink, "_blank"); // Open the link in a new tab
    });
  };
  return (
    <Modal
      show={props.showBrandingModal}
      onHide={props.handleBrandingModalClose}
      size="lg"
      id="playermoreinfo"
    >
      <div className="player_star_info">
        <div className="player_star_pic">
          <Link
            className="player_profile_url"
            onClick={(e) => e.preventDefault()}
          >
            <figure className="player_profile_url">
              {brandingDetails && (
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}branding/${brandingDetails.logo}`}
                  alt="img"
                />
              )}
              {!brandingDetails && (
                <img src={`../images/small-brand-logo.jpg`} alt="img" />
              )}
            </figure>
          </Link>
          <div className="player_description">
            {/* <h3 className="breakWord">{clubDetails?.game_name}</h3> */}
            <h3 className="breakWord">{brandingDetails?.name}</h3>
            <div className="player_game_name">{clubDetails?.game_name}</div>
            <div className="player_game_name">
              <ul className="social_links">
                {brandingDetails?.url && (
                  <li>
                    <Link
                      onClick={(e) =>
                        saveUserClick(
                          brandingDetails?.url,
                          "branding_website",
                          brandingDetails?.id
                        )
                      }
                    >
                      <i className="fas fa-globe"></i>
                    </Link>
                  </li>
                )}
                {brandingDetails?.facebook && (
                  <li>
                    <Link
                      onClick={(e) =>
                        saveUserClick(
                          brandingDetails?.facebook,
                          "branding_facebook",
                          brandingDetails?.id
                        )
                      }
                    >
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                  </li>
                )}
                {brandingDetails?.twitter && (
                  <li>
                    <Link
                      onClick={(e) =>
                        saveUserClick(
                          brandingDetails?.twitter,
                          "branding_twitter",
                          brandingDetails?.id
                        )
                      }
                    >
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                )}

                {brandingDetails?.instagram && (
                  <li>
                    <Link
                      onClick={(e) =>
                        saveUserClick(
                          brandingDetails?.instagram,
                          "branding_instagram",
                          brandingDetails?.id
                        )
                      }
                    >
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="club_logo">
          <figure>
            {clubDetails && (
              <img
                src={`${process.env.REACT_APP_UPLOAD_URL}game_logo/${clubDetails?.logo}`}
                alt="img"
              />
            )}
            {!clubDetails && <img src={`../images/club-logo.png`} alt="img" />}
          </figure>
        </div>
      </div>

      <div
        className="player-performance1"
        style={{ maxHeight: "500px", overflowY: "auto" }}
      >
        {ReactHtmlParser(brandingDetails?.about)}
      </div>
      <Modal.Footer>
        <div className="poweredby">
          Powered By
          <img src="images/logo.png" alt="img" />
        </div>
        <div className="">
          <Link
            className="border-btn"
            onClick={(e) => {
              e.preventDefault();
              props.handleBrandingModalClose();
            }}
          >
            <i className="fas fa-times"></i>
          </Link>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BrandingDetailModal;
